import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const PageNotFound = () => (
  <HelmetProvider>
    <div className="not-found">
      <Helmet title="404 Not Found">
        <meta name="description" content="The content you are looking for cannot be found." />
      </Helmet>
      <h1 data-testid="heading">Page Not Found or Currently Being Updated</h1>
      <h1 data-testid="heading">👨🏼‍💻</h1>
      <h1 data-testid="heading">Please Check Back Later, Something Awesome is Happening Behind the Scences</h1>
      <p> <Link to="/">Click to Return Home</Link>.</p>
    </div>
  </HelmetProvider>
);

function add_img() { 
	var img = document.createElement('img'); 
    img.src = 'https://media.geeksforgeeks.org/wp-content/uploads/20190529122828/bs21.png'; 
	document.getElementById('body').appendChild(img);
}
export default PageNotFound;
